<template>
    <div class="flex flex-wrap mb-6 mt-9">
        <loading :show="show" :label="label"> </loading>
        <div
            class="w-full md:w-2/3 px-3 mb-6 md:mb-0 bg-white shadow px-4 py-7"
        >
            <div class="input-wrapper border-b pb-6">
                <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-first-name"
                >
                    Enter Meter Number
                </label>
                <PincodeInput
                    v-model="form.meterNumber"
                    :length="11"
                    placeholder="0"
                />
            </div>

            <div class="mt-6">
                <div class="flex flex-wrap -mx-3 mb-6">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label
                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-first-name"
                        >
                            Meter Number
                        </label>
                        <input
                            tabindex="-1"
                            v-model="form.meterNumber"
                            class="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            type="number"
                            readonly
                        />
                        <!-- <p class="text-red-500 text-xs italic">
              Please fill out this field.
            </p> -->
                    </div>
                    <div class="w-full md:w-1/2 px-3">
                        <label
                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-last-name"
                        >
                            Customer's Name
                        </label>
                        <input
                            tabindex="-1"
                            v-model="form.meterName"
                            class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name"
                            type="text"
                            readonly
                        />
                        <p class="text-sm text-gray-500 mt-2">
                            Please Confirm With the buyer if the name above owns
                            the meter.
                        </p>
                    </div>
                </div>
            </div>

            <div
                class="flex flex-col justify-end items-end text-right mt-20 border-b"
            >
                <div class="w-80 px-3 mb-6">
                    <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-first-name"
                    >
                        Amount
                    </label>
                    <input
                        class="appearance-none block w-full bg-gray-50 font-extrabold text-2xl text-right text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        v-model="form.amount"
                        type="number"
                        placeholder="0.00"
                    />
                    <!-- <p class="text-red-500 text-xs italic">
              Please fill out this field.
            </p> -->
                </div>

                <div class="w-80 px-3 mb-6">
                    <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-first-name"
                    >
                        Cashpower Amount
                    </label>
                    <input
                        tabindex="-1"
                        class="appearance-none block w-full bg-gray-50 font-extrabold text-2xl text-right text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        :value="`GMD ${form.cp_amount}`"
                        readonly
                    />
                    <!-- <p class="text-red-500 text-xs italic">
              Please fill out this field.
            </p> -->
                </div>

                <div class="w-80 px-3 mb-6">
                    <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-first-name"
                    >
                        Transaction Fee
                    </label>
                    <input
                        tabindex="-1"
                        class="appearance-none block w-full bg-gray-50 font-extrabold text-2xl text-right text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        :value="`GMD ${form.fee}`"
                        type="text"
                        readonly
                    />
                    <!-- <p class="text-red-500 text-xs italic">
              Please fill out this field.
            </p> -->
                </div>
            </div>

            <div class="flex flex-col justify-end items-end text-right mt-10">
                <div class="w-80 px-3 mb-6">
                    <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-first-name"
                    >
                        Total
                    </label>
                    <h1
                        class="flex flex-col justify-end items-end text-right mt-4 text-4xl"
                    >
                        GMD :
                    </h1>

                    <!-- <p class="text-red-500 text-xs italic">
              Please fill out this field.
            </p> -->
                </div>

                <div class="w-80 px-3 mb-6">
                    <button
                        :disabled="form.meterNumber.length < 7"
                        @click="consumerCheck()"
                        class="bg-green-400 font-bold hover:bg-green-500 py-3 px-9 rounded-lg text-white"
                    >
                        <span>Continue</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0 bg-white shadow">
            <div class="input-wrapper border-b py-6 flex justify-center">
                <button
                    @click="print()"
                    class="uppercase text-gray-700 font-bold py-3 px-4 border border-gray-400 rounded-lg hover:bg-gray-100"
                >
                    <i class="bx bx-printer"></i>

                    Print
                </button>
            </div>

            <div>
                <!-- {{ isComplatePrint }} -->
            </div>

            <div
                v-if="receipt"
                id="section-to-print"
                style="margin-top: 10px"
                class="flex justify-center"
            >
                <recipet
                    :isLoading="showReceipt"
                    :data="receipt"
                    :user="user"
                />
            </div>
            <!-- <div v-if="showTransactionNotice" id="section-to-print" style="margin-top: 10px" class="flex justify-center">
        <TransactionNotice :closeNotice="closeNotice" />
      </div> -->
        </div>
    </div>
</template>

<script>
import Form from 'vform';
import moment from 'moment';
import Swal from 'sweetalert2';
import axios from 'axios';
import PincodeInput from 'vue-pincode-input';
import Recipet from '../../../components/Recipet.vue';
// import TransactionNotice from '../../../components/TransactionNotice.vue';
import JSPM from '../../../../public/JSPrintManager';
import loading from 'vue-full-loading';

export default {
    props: ['user', 'serviceStatus'],

    components: {
        Recipet,
        loading,
        PincodeInput,
    },

    data: () => ({
        applyPaymentRes: null,

        form: new Form({
            amount: null,
            meterNumber: '',
            meterName: '',
            fee: '',
            cp_amount: '',
            phone: null,
            consumerResponse: null,
        }),
        clear: false,
        isVending: false,
        showReceipt: false,
        showTransactionNotice: false,
        isComplate: '',
        receipt: null,
        confirmAmount: '',
        checkConsumer: false,
        isLoading: false,
        show: false,
        print2default: true,
        selectedPrint: null,
        label: 'Loading...',
    }),

    methods: {
        closeNotice() {
            this.showTransactionNotice = false;
        },

        async checkServiceStatus() {
            this.isLoading = true;

            try {
                const { data } = await axios.get('/service-status/NAWEC');
                this.serviceStatus = data.is_live;

                if (!this.serviceStatus) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Service Unavailable!',
                        text: data.message,
                    });
                    this.isLoading = false;
                    this.show = false;
                    return false;
                }
                return true;
            } catch (error) {
                console.error('Service status check failed', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Could not verify service status. Please try again later.....',
                });
                this.isLoading = false;
                this.show = false;
                return false;
            }
        },
        async vend() {
            if (!this.serviceStatus) {
                Swal.fire({
                    icon: 'error',
                    title: 'Service Unavailable!',
                    text: 'NAWEC services are currently down. Please try again later.',
                });
                this.isLoading = false;
                this.show = false;
                return false;
            }
            // navigator.geolocation.getCurrentPosition(setPosition);

            Swal.fire({
                title: 'confirmation?',
                html: `Meter Number: <b>${this.form.meterNumber}</b><br/>
          Customer: <b>${this.form.meterName}</b><br/>
          - <b>${this.form.consumerResponse.serviceAddress}</b><br/>
          Amount: <b>GMD ${this.form.amount}</b><br/>
          Fee: <b>GMD ${this.form.consumerResponse.fee}</b><br/>
          Cashpower Amount: <b>GMD ${
              this.form.amount - this.form.consumerResponse.fee
          }</b><br/>

          Units: <b>${this.form.consumerResponse.units}kWh </b><br/>
          Phone Number: <b>${
              this.form?.phone
                  ? this.form?.phone
                  : "<input class='appearance-none block w-full bg-gray-50 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white px-2'  id='phone' placeholder='Enter the phone number of customer' >"
          }
     <br>Please re-enter amount below
          <input class='appearance-none block w-full bg-gray-50 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white px-2'  id='confirm-amount' placeholder='Enter amount' >

          `,
                preConfirm: () => {
                    if (document.getElementById('phone')) {
                        return [
                            document.getElementById('phone').value,
                            document.getElementById('confirm-amount').value,
                        ];
                    } else {
                        return [
                            document.getElementById('confirm-amount').value,
                        ];
                    }
                },
                className: 'confirm-amount',
                type: 'warning',
                // footer: 'fooo',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, i confirm!',
            }).then(result => {
                console.log('resul lenght', result.length);
                console.log('---REsult', result);
                let amount;
                if (result.value.length > 1) {
                    this.form.phone = result.value[0];
                    amount = result.value[1];
                } else {
                    amount = result.value[0];
                }

                console.log('this is the phone number', this.form.phone);
                // console.log("Amount", this.form.amount, "Confirm",this.confirmAmount)
                if (this.form.amount !== amount) {
                    // Swal.fire({
                    //   icon: "error",
                    //   title: "Something went wrong!",
                    //   text: "Please enter correct amount",
                    // });
                    this.show = false;
                    this.isLoading = false;
                    this.clearForm();
                    return false;
                }
                if (result) {
                    console.log('inside vend');
                    console.log('inside vend', result.value);
                    console.log('inside vend resulrt', result);
                    this.isLoading = true;
                    this.show = true;

                    this.form
                        .post(`/vendcashpower`)
                        .then(response => {
                            console.log(response);
                            if (response.data.status === '55') {
                                // throw new Error(response.data.error);
                                Swal.fire({
                                    icon: 'info',
                                    title: "Transaction Can't be complated!",
                                    text: 'Please inform your admin',
                                });

                                this.show = false;
                                this.isLoading = false;

                                return false;
                            }
                            if (!response.data.list_tokens) {
                                this.show = false;
                                this.isLoading = false;
                                Swal.fire({
                                    title: 'Processing Transaction?',
                                    text: 'We are processing your transaction in the background due to a slight delay at NAWEC. You can use the Retry button or check from your transactions list. Please avoid creating new transactions with the same meter number until this one completes.',
                                    confirmButtonText: `Retry`,
                                    denyButtonText: `Continue`,
                                }).then(result => {
                                    /* Read more about isConfirmed, isDenied below */
                                    if (result.isConfirmed) {
                                        this.isLoading = true;
                                        this.show = true;

                                        this.reqStsToken(this.applyPaymentRes);
                                    } else if (result.isDenied) {
                                        // Swal.fire("Changes are not saved", "", "info");
                                    }
                                });
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                return false;
                            } else {
                                this.applyPaymentRes = response.data;
                                this.receipt = response.data;
                                console.log('recipet.....', this.receipt);
                            }
                        })
                        .then(() => {
                            this.showReceipt = true;

                            if (this.receipt && !this.receipt.list_tokens) {
                                // this.showTransactionNotice = true;
                            } else {
                                this.print(this.receipt);
                            }
                            this.show = false;
                            this.isLoading = false;

                            this.clearForm();

                            // setTimeout(() => {
                            //   this.reqStsToken(this.applyPaymentRes);

                            //   this.clearForm();
                            // }, 6000);
                        })
                        .catch(function (error) {
                            console.log('thisis not d,,', error);
                            this.isLoading = false;

                            this.show = false;
                            this.isLoading = false;
                            this.clearForm();
                            Swal.fire('Sorry!', `${error}`, 'error');
                        });
                }
            });
        },

        async reqStsToken(payload) {
            // console.log("payload", payload);
            // console.log("Apply Payment", this.applyPaymentRes);

            try {
                const response = await axios.get(
                    `/tx/get_sts_token/${payload.data.transaction_id}`
                );

                // Check if the request has token
                if (response.data.data.status == true) {
                    this.showReceipt = true;
                    this.receipt = response.data.data;

                    this.show = false;
                    this.isLoading = false;
                    this.print(this.receipt);
                } else {
                    // User retry
                    this.isLoading = false;
                    this.show = false;

                    Swal.fire({
                        title: 'Processing Transaction?',
                        text: 'We are processing your transaction in the background due to a slight delay at NAWEC. You can use the Retry button or check from your transactions list. Please avoid creating new transactions with the same meter number until this one completes.',
                        showDenyButton: true,
                        showCancelButton: true,
                        confirmButtonText: `Retry`,
                        denyButtonText: `Continue`,
                    }).then(result => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            this.isLoading = true;
                            this.show = true;

                            this.reqStsToken(this.applyPaymentRes);
                        } else if (result.isDenied) {
                            // Swal.fire("Changes are not saved", "", "info");
                        }
                    });
                }

                return true;
            } catch (e) {
                console.log('Error');
                this.isLoading = false;
                this.show = false;

                return false;
            }
        },

        clearForm() {
            this.form.amount = null;
            this.form.meterNumber = '';
            this.form.meterName = '';
            this.form.fee = '';
            this.form.cp_amount = '';
            this.form.conclearsumerResponse = null;
            this.form.phone = null;
            // this.showReceipt = false;
            this.confirmAmount = null;
            // this.receipt = n;
            this.clear = false;
        },

        async consumerCheck() {
            const serviceAvailable = await this.checkServiceStatus();
            if (!serviceAvailable) {
                this.isLoading = true;

                return;
            }
            // Submit the form
            this.receipt = null;
            this.showReceipt = false;

            this.isLoading = true;
            this.show = true;
            try {
                const response = await this.form.post('/calculate_payment');

                // console.log("RESPONSE", response.data);

                this.form.consumerResponse = response.data.data;
                this.form.meterName = this.form.consumerResponse.CustomerName;
                this.form.fee = this.form.consumerResponse.fee;
                this.form.cp_amount =
                    this.form.amount - this.form.consumerResponse.fee;
                this.form.phone = response.data.data.phone;
                this.checkConsumer = true;

                this.isLoading = false;
                this.show = false;
                // this.$router.push({ path: "/agents" });

                this.vend();
                return true;
            } catch (e) {
                this.isLoading = false;
                this.show = false;

                console.log('Error');
                return false;
            }
        },

        print: function () {
            try {
                console.log('opppsddndjjdjdjdss');
                if (this.selected_printer === '' && !this.print2default) {
                    alert('You must select a printer');
                    return;
                }

                console.log('checking....');
                var cpj = new JSPM.ClientPrintJob();
                console.log('cps test', cpj);
                if (this.receipt) {
                    if (this.print2default) {
                        console.log('opppps okay');
                        cpj.clientPrinter = new JSPM.DefaultPrinter();
                    } else {
                        console.log('cheking else');
                        cpj.clientPrinter = new JSPM.InstalledPrinter(
                            this.selected_printer
                        );
                    }

                    var day = new Date(this.receipt.created_at);
                    console.log('its has reach hereeeee', day);

                    var dateCreated = moment()
                        .utc(day)
                        .format('Do MMM, YYYY, h:mm:ss a');

                    var esc = '\x1B';
                    var newLine = '\x0A';
                    var fullCut = esc + 'm';

                    var cmds = esc + '@';
                    cmds += esc + '!' + '\x38';
                    cmds += 'CHAPMAN XPRESS';
                    cmds += newLine;
                    cmds += esc + '!' + '\x00';

                    cmds += newLine + newLine;

                    cmds += this.user.data.staff.branch;
                    cmds += newLine;
                    cmds += `Receipt No .: ${this.receipt.receipt}`;
                    cmds += newLine + newLine;
                    cmds += `Meter No .: ${this.receipt.meter_serial}`;
                    cmds += newLine;

                    cmds += esc + '!' + '\x22';
                    cmds += `${this.receipt.customer_name}`;
                    cmds += esc + '!' + '\x00';

                    cmds += newLine;
                    cmds += `${this.receipt.meter_serial}`;
                    cmds += newLine + newLine;
                    cmds += '------------------ Token Number ---------------';
                    cmds += newLine;
                    cmds += esc + '1' + '\x01  ';

                    cmds += newLine;
                    cmds += '********************************************';
                    cmds += newLine + newLine;
                    cmds += esc + '!' + '\x30';

                    // if (
                    //     this.receipt.list_tokens &&
                    //     this.receipt.list_tokens.length > 0
                    // ) {
                    //     const formattedTokens = this.receipt.list_tokens.map(
                    //         token => this.formatToken(token)
                    //     );
                    //     cmds += formattedTokens.join(', ');
                    // }

                    if (
                        this.receipt.list_tokens &&
                        this.receipt.list_tokens.length > 0
                    ) {
                        const formattedTokens = this.receipt.list_tokens.map(
                            token => this.formatToken(token)
                        );
                        cmds += '\n';
                        cmds += formattedTokens.join('\n');
                    }

                    cmds += newLine + newLine;
                    cmds += esc + '!' + '\x00';

                    cmds += '********************************************';
                    cmds += newLine + newLine;

                    cmds += `Amount Paid .:       GMD ${this.receipt.total_payment}`;
                    cmds += newLine;
                    cmds += `Fee .:               GMD ${this.receipt.fee}`;
                    cmds += newLine;
                    cmds += `Cashpower Amount .:  GMD ${this.receipt.amount}`;
                    cmds += newLine;
                    cmds += `Total Units .:       ${this.receipt.units}kWh`;
                    cmds += newLine;

                    cmds += `Payment Date .:      ${dateCreated}`;

                    cmds += newLine + newLine;
                    cmds += esc + '!' + '\x18';
                    cmds += `Operator: ${this.user.data.name}`;
                    cmds += newLine;
                    cmds += esc + '!' + '\x00';

                    cmds += 'Use Energy Wisely!';
                    cmds += newLine;

                    cmds += newLine;
                    cmds += `Phone: +220 438 3223 - 307 4455 - ${this.user.data.staff.branch_contact_phone}`;
                    cmds += newLine + newLine;
                    cmds += newLine + newLine;

                    cmds += fullCut;

                    cpj.printerCommands = cmds;
                    cpj.sendToClient();
                    console.log('its has reach hereeeee');
                }
            } catch (error) {
                console.log('error in print', error);
            }
        },

        formatToken(token) {
            return token.match(/\d{4}(?=\d{2,3})|\d+/g).join('-');
        },

        onInit: function () {
            console.log('IN INIT', JSPM);
            var _this = this;
            JSPM.JSPrintManager.auto_reconnect = true;
            JSPM.JSPrintManager.start();
            JSPM.JSPrintManager.WS.onStatusChanged = function () {
                _this.getPrinters().then(p => {
                    _this.printers = p;
                    _this.$nextTick();
                });
            };
        },

        getPrinters: function () {
            return new Promise((ok, err) => {
                let printers = [];
                if (
                    JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open
                ) {
                    JSPM.JSPrintManager.getPrinters()
                        .then(function (myPrinters) {
                            printers = myPrinters;
                            console.log(printers);
                            ok(printers);
                        })
                        .catch(e => err(e));
                } else {
                    console.warn('JSPM WS not open');
                    ok(printers);
                }
            });
        },
    },

    mounted: function () {
        this.onInit();
    },
};
</script>
